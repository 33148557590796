import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 30px 0",
	"background": "#4e1dff",
	"quarkly-title": "Footer-14"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 40px 0px",
			"md-margin": "0px 0px 45px 0px",
			"justify-content": "space-between",
			"align-items": "center",
			"md-flex-direction": "column",
			"md-display": "flex",
			"md-grid-gap": "32px",
			"height": "auto"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 600 28px/1.2 --fontFamily-sans",
			"color": "--light",
			"md-margin": "0px 0px 0 0px",
			"md-width": "30%",
			"display": "inline-block",
			"text-decoration-line": "initial",
			"href": "#",
			"children": "Автомийка ShineMaster"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "36px 54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "36px 24px",
			"md-width": "100%",
			"sm-width": "100%",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"text-transform": "uppercase"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"lg-justify-content": "start",
			"height": "auto"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"children": "ГОЛОВНА"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"lg-justify-content": "start",
			"height": "auto"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "/services",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"children": "ПОСЛУГИ"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"lg-justify-content": "start",
			"height": "auto"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "/contacts",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"children": "КОНТАКТИ"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Link {...override("link")} />
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link1")} />
				</Box>
				<Box {...override("box3")}>
					<Link {...override("link2")} />
				</Box>
				<Box {...override("box4")}>
					<Link {...override("link3")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;